// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ustav-js": () => import("./../../../src/pages/ustav.js" /* webpackChunkName: "component---src-pages-ustav-js" */),
  "component---src-templates-basic-template-js": () => import("./../../../src/templates/basicTemplate.js" /* webpackChunkName: "component---src-templates-basic-template-js" */),
  "component---src-templates-section-template-js": () => import("./../../../src/templates/sectionTemplate.js" /* webpackChunkName: "component---src-templates-section-template-js" */)
}

